<template>
  <div>
    <div class="d-flex justify-end sticky">
      <v-btn v-bind="$config.buttonAttrs.floating" :to="{ name: 'NewPlate' }" title="Hozzáadás">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Rendszámok</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.owner_name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Tulajdonos"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.card_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Kártyaszám"
                :mask="$config.inputMasks.cardNumber"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.plate_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Rendszám"
                :mask="$config.inputMasks.plateNumber"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.owner`]="{ item }">
          {{ item.card_owner.name }}
        </template>
        <template #[`item.card`]="{ item }">
          {{ item.card.card_number }}
        </template>
        <template #[`item.virtual`]="{ item }">
          <template v-if="item.card.v">
            Nem
            <v-btn
              small
              fab
              text
              color="secondary"
              depressed
              class="ml-2"
              @click.stop="selectedPlate = item"
            >
              <v-icon>mdi-paperclip</v-icon>
            </v-btn>
          </template>
          <template v-else>Igen</template>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            text
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm"
            :to="{
              name: 'PlateEditor',
              params: { id: item.id },
            }"
            title="Szerkesztés"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <attach-modal v-if="selectedPlate" :plate="selectedPlate" @close="selectedPlate = null" />
  </div>
</template>

<script>
import AttachModal from './AttachModal';
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  components: {
    AttachModal,
  },

  data() {
    return {
      routeName: 'PlateList',
      fetchItems: this.fetchPlates,
      selectedPlate: null,
      dataTable: {
        options: {
          sortBy: ['CardOwners.name'],
        },
        headers: [
          { text: 'Tulajdonos', value: 'owner', sortName: 'CardOwners.name' },
          { text: 'Kártyaszám', value: 'card', sortName: 'Cards.card_number' },
          { text: 'Rendszám', value: 'number' },
          { text: 'Kártyához kapcsolt rendszám', value: 'virtual', sortable: false },
          { value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        plate_number: '',
        card_number: '',
        owner_name: '',
      },
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        card_number: this.$route.query.card_number,
        owner_name: this.$route.query.owner_name,
        plate_number: this.$route.query.plate_number,
      };
    },

    async fetchPlates() {
      this.loading = true;
      try {
        const response = await this.$http.post('plates/list', this.postData);
        this.dataTable.items = response.plates;
        this.dataTable.itemsLength = response.plates_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        card_number: this.search.card_number || '',
        owner_name: this.search.owner_name || '',
        plate_number: this.search.plate_number || '',
      };
    },
  },
};
</script>
