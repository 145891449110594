import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    attrs: {
      "value": true,
      "max-width": "480"
    },
    on: {
      "click:outside": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c(VCard, {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c(VAppBar, {
    staticClass: "mb-6",
    attrs: {
      "flat": "",
      "color": "primary"
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": "",
      "left": ""
    }
  }, [_vm._v("mdi-paperclip")]), _c(VToolbarTitle, {
    staticClass: "text-h6 white--text pl-0"
  }, [_vm._v(" Rendszám kártyához kapcsolása ")])], 1), _c(VCardText, [_c(VCard, {
    staticClass: "pa-4",
    attrs: {
      "color": "muted",
      "elevation": "0"
    }
  }, [_vm._v(" Kiválasztott rendszám: "), _c(VSimpleTable, {
    staticClass: "mt-2 muted",
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-left pl-0 font-weight-bold",
          attrs: {
            "width": "1"
          }
        }, [_vm._v("Név")]), _c('td', [_vm._v(_vm._s(_vm.plate.card_owner.name))])]), _c('tr', [_c('td', {
          staticClass: "text-left pl-0 font-weight-bold"
        }, [_vm._v("Rendszám")]), _c('td', [_vm._v(_vm._s(_vm.plate.number))])])])];
      },
      proxy: true
    }])
  })], 1), _c(VAutocomplete, {
    staticClass: "mt-8",
    attrs: {
      "loading": _vm.loading,
      "items": _vm.cards,
      "item-text": _vm.itemText,
      "item-value": "card_number",
      "filled": "",
      "elevation": "0",
      "hide-details": "",
      "label": "Kártya kiválasztása"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.card_number) + " (" + _vm._s(item.owner_name) + ") ")];
      }
    }]),
    model: {
      value: _vm.select,
      callback: function ($$v) {
        _vm.select = $$v;
      },
      expression: "select"
    }
  })], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Mégse ")]), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Mentés ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }